import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';
import useSWR from 'swr';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { type Action, ActionSheet } from '../components/ActionSheet';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import { useGameLikeListLoader } from '../components/Game/GameCenter/List';
import { EditIcon } from '../components/icons/EditIcon';
import { EyeIcon } from '../components/icons/EyeIcon';
import { PlayIcon } from '../components/icons/PlayIcon';
import { Loading } from '../components/Loading';
import { OrganizationSelect } from '../components/Organization';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';
import { DateUtils } from '../utils/date';
import { CourseLayout } from './admin.courses.client';

export async function clientLoader() {
  return null;
}

function CourseInfo(props: { pack: GamePack }) {
  const { pack } = props;
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const { data: creator } = useSWR(['users', pack.uid], async () => {
    try {
      const resp = await apiService.organization.getOrganizerByUid(pack.uid);
      return {
        username: resp.data.organizer.firstName,
        orgName: resp.data.organizer.organization?.name,
      };
    } catch (error) {
      const resp = await apiService.user.getUser(pack.uid);
      return {
        username: resp.data.user.username ?? resp.data.user.email,
        orgName: null,
      };
    }
  });

  const { data: progressionsCount } = useSWR(
    ['pack-progressions-count', pack.id],
    async () => {
      const resp = await apiService.progression.getPackProgressionsCount(
        pack.id
      );
      return resp.data.count;
    }
  );

  const { data: files } = useSWR(
    `/game-packs/${pack.id}/ugc-files`,
    async () => {
      const resp = await apiService.gamePack.getUGCFiles(pack.id);
      return resp.data.files;
    }
  );

  const navigate = useNavigate();

  const viewFiles = () => {
    if (!files || files.length === 0) return;
    navigate(`./${pack.id}/files`);
  };

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      onClick: () => {
        navigate(`/trainings/${pack.id}/edit`);
      },
    },
    {
      kind: 'button',
      key: 'play',
      icon: <PlayIcon />,
      text: 'Play',
      onClick: () => {
        navigate(`/game-packs/${pack.id}/overworld`);
      },
    },
    {
      kind: 'button',
      key: 'view-progressions',
      icon: <EyeIcon />,
      text: 'View Progressions',
      onClick: () => {
        navigate(`/admin/courses/${pack.id}/progressions`);
      },
    },
  ];

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td
        className='underline cursor-pointer'
        onClick={() => setActiveGamePack(pack)}
      >
        {pack.name}
      </td>
      <td>
        <Link className='underline' to={`/admin/users?uid=${pack.uid}`}>
          {creator?.username}
        </Link>
      </td>
      <td>{creator?.orgName ?? 'N/A'}</td>
      <td>{DateUtils.FormatDatetime(pack.createdAt)}</td>
      <td
        className={`${
          files && files.length > 0 ? 'cursor-pointer underline' : ''
        }`}
        onClick={viewFiles}
      >
        {files?.length}
      </td>
      <td>
        <Link
          to={`/admin/courses/${pack.id}/progressions`}
          className='cursor-pointer underline'
        >
          {progressionsCount}
        </Link>
      </td>
      <td>
        <ActionSheet
          actions={actions}
          btnSizingClassName='w-7.5 h-7.5'
          placement='bottom-end'
        />
      </td>
    </tr>
  );
}

type FormData = {
  q: string;
  orgId: string | null;
};

export function Component() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  const orgId = searchParams.get('org-id');
  const paginator = useMemo(
    () =>
      apiService.gamePack.searchGamePacks(q ?? '', {
        version: EnumsGamePackVersion.GamePackVersionV1,
        timeSort: 'created_at',
        orgId: orgId ?? undefined,
      }),
    [q, orgId]
  );
  const { items, state, error, handleLoadMore } =
    useGameLikeListLoader(paginator);
  const canLoad = state.isStarted && !state.isRunning && !error;

  const { register, handleSubmit, setValue, control } = useForm<FormData>({
    defaultValues: { q: q ?? '', orgId },
  });

  const hasActiveFilters = q || orgId;

  const clearFilters = () => {
    setSearchParams(new URLSearchParams());
  };

  useEffect(() => {
    setValue('q', q ?? '');
  }, [q, setValue]);

  useEffect(() => {
    setValue('orgId', orgId);
  }, [orgId, setValue]);

  const onSubmit = handleSubmit((data) => {
    setSearchParams((prev) => {
      if (data.q) {
        prev.set('q', data.q);
      } else {
        prev.delete('q');
      }
      if (data.orgId) {
        prev.set('org-id', data.orgId);
      } else {
        prev.delete('org-id');
      }
      return prev;
    });
  });

  return (
    <CourseLayout contentKey='course-list'>
      <div className='w-full relative text-white p-10'>
        <header className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl'>Training Courses</h1>
        </header>

        <form
          className='mt-10 flex items-center gap-4'
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <input
            type='text'
            placeholder='Keywords'
            className='field mb-0 w-80 h-10 p-2'
            {...register('q')}
          />
          <Controller<FormData>
            control={control}
            name='orgId'
            render={({ field: { value, onChange } }) => {
              return (
                <OrganizationSelect
                  orgId={value}
                  onChange={(org) => {
                    onChange(org?.id ?? null);
                  }}
                  isClearable
                />
              );
            }}
          />
          <button type='submit' className='btn-primary w-30 h-10'>
            Search
          </button>
          {hasActiveFilters && (
            <button
              type='button'
              className='btn text-primary text-xs'
              onClick={clearFilters}
            >
              Reset Filters
            </button>
          )}
        </form>

        <table className='w-full mb-10'>
          <thead className='text-left h-12 text-base text-bold'>
            <tr>
              <th>Pack Name</th>
              <th>Creator</th>
              <th>Organization</th>
              <th>Created At</th>
              <th>Attached Files</th>
              <th>Progressions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((pack) => (
              <CourseInfo key={pack.id} pack={pack} />
            ))}
          </tbody>
        </table>
        {state.isRunning && (
          <div className='w-full flex items-center justify-center text-white my-8'>
            <Loading />
          </div>
        )}
        {canLoad && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </CourseLayout>
  );
}
